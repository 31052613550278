import '../src/application.scss';
import TurbolinksAdapter from 'vue-turbolinks';
import jstz from 'jstz';
import 'select2';
import './subscription.js';
import 'choices.js/public/assets/scripts/choices.min.js';
import 'controllers';

const tz = jstz.determine();
document.cookie = 'timezone' + '=' + tz.name() + '; path=/';

document.addEventListener("turbolinks:request-start", function(event) {
  var xhr = event.data.xhr;
  xhr.setRequestHeader("X-Turbolinks-Nonce", $("meta[name='csp-nonce']").prop('content'));
});

document.addEventListener('turbolinks:load', () => {
  var $select           = $('select.select_rails');

  if ($select.length > 0)                         $select.select2();
});

document.addEventListener('turbolinks:before-cache', () => {
  $('script[nonce]').each(function(index, element) {
    $(element).attr('nonce', element.nonce)
  })
});

document.addEventListener('turbolinks:before-cache', function () {
  var $select = $('select.select_rails');

  if ($select.length > 0) $select.each(function () {
    $(this).select2('destroy');
  });
});