import { Controller } from 'stimulus'
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['part_number'];

  connect() {
    this.timeout = null;
  }

  searchPart() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { this.updateForm(); }, 1000);
  }

  cleanInputs() {
    document.getElementById('description').value = null;
    document.getElementById('brand_name').value = null;
    document.getElementById('life_time_years').value = null;
    document.getElementById('life_time_months').value = null;
    document.getElementById('life_time_days').value = null;
    document.getElementById('overhaul_date').value = null;
    document.getElementById('notification_date').value = null;
    document.getElementById('hours').value = null;
    document.getElementById('notification_hours').value = null;
    M.updateTextFields();
  }

  updateForm() {
    let part_number = document.getElementById('part_number').value;
    if (part_number == '') {
      this.cleanInputs(this);
      return;
    };

    const params = { part_number: part_number };

    Rails.ajax({
      context: this,
      type: 'get',
      dataType: 'json',
      url: '/en/parts/show',
      data: new URLSearchParams(params).toString(),
      success: function (partContent) {
        if (partContent.part_number) {
          this.context.cleanInputs(this);

          document.getElementById('description').value = partContent.description;
          document.getElementById('brand_name').value = partContent.brand_name;
          document.getElementById('life_time_years').value = partContent.life_time_years;
          document.getElementById('life_time_months').value = partContent.life_time_months;
          document.getElementById('life_time_days').value = partContent.life_time_hours;

          if (partContent.overhaul_date) {
            document.getElementById('overhaul_date').value = partContent.overhaul_date;
            document.getElementById('notification_date').value = partContent.notification_date;
          }

          if (partContent.hours) {
            document.getElementById('hours').value = partContent.hours;
            document.getElementById('notification_hours').value = partContent.hours - 20;
          }

        }

        M.updateTextFields();
      },
    });
  }

  placeholderDefaultNotificationHours(e) {
    let notication_time = e.currentTarget.value

    if (notication_time > 20) {
      document.getElementById('notification_hours').value = e.currentTarget.value - 20;
    } else {
      document.getElementById('notification_hours').value = null;
    }

    M.updateTextFields();
  }

  setLifeTime() {
    this.setDate('overhaul_date', 0);
    this.setDate('notification_date', 10);
  }

  setDate(id, less_days) {
    let years = document.getElementById('life_time_years').value;
    let months = document.getElementById('life_time_months').value;
    let days = document.getElementById('life_time_days').value;

    if (years || months || days) {
      const params = {
        years: years,
        months: months,
        days: days,
        less_days: less_days
      };

      Rails.ajax({
        context: this,
        type: 'get',
        dataType: 'json',
        url: '/en/parts/new',
        data: new URLSearchParams(params).toString(),
        success: function (date) {
          document.getElementById(id).value = date.date;
          M.updateTextFields();
        },
      });
    } else {
      document.getElementById(id).value = null;
      M.updateTextFields();
    }

  }
}