import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    M.updateTextFields();
    this.termlyPopUp();
    this.initiateInstanceActionBtn();
    this.initiateInstanceSlideOutMenu();
    this.initiateInstanceModal();
    this.initiateInstanceDatePikersTo();
    this.initiateInstanceDatePikersFrom();
    this.initiateInstanceTimePikers();
    this.initiateInstanceCarouselBanner();
    this.initiateInstanceCarouselRender();
    this.initiateInstanceMaterialbox();
    this.initiateInstanceParallax();
    this.initiateInstanceCollapsible();
    this.initiateInstanceTooltipped();
    this.initiateInstanceChoice();
    this.initiateInstanceAutocomplete();
  }

  disconnect() {
    this.destroyInstanceActionBtn();
    this.destroyInstanceSlideOutMenu();
    this.destroyInstanceModal();
    this.destroyInstanceDatePikersTo();
    this.destroyInstanceDatePikersFrom();
    this.destroyInstanceTimePikers();
    this.destroyInstanceCarouselBanner();
    this.destroyInstanceCarouselRender();
    this.destroyInstanceMaterialbox();
    this.destroyInstanceParallax();
    this.destroyInstanceCollapsible();
    this.destroyInstanceTooltipped();
    this.destroyInstanceChoice();
    this.destroyInstanceAutocomplete();
  }

  termlyPopUp() {
    let railsEnv = document.querySelector('body').dataset.railsEnv;
    // termly contract pop up
    if (railsEnv != 'test') {
      let s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://app.termly.io/embed.min.js';
      s.id = 'a505db8e-e484-4f03-81ad-dbf3a0d2e40e';
      s.setAttribute('data-name', 'termly-embed-banner');
      let x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
  }

  initiateInstanceSlideOutMenu() {
    this.slidOutMenu = document.querySelector('#slide-out');
    if (this.slidOutMenu) { this.slideOutMenuInstance = new M.Sidenav(this.slidOutMenu); };
  }
  destroyInstanceSlideOutMenu() {
    if (this.slideOutMenuInstance) this.slideOutMenuInstance.destroy();
  }

  initiateInstanceModal() {
    this.modalInstanceList = [];
    this.modal = document.querySelectorAll('.modal');

    if (this.modal) { this.modal.forEach((element, index, array) => {
        this.modalInstanceList.push(M.Modal.init(element, {}));
      }, this);
    };
  }
  destroyInstanceModal() {
    if (this.modalInstanceList.length > 0) {
      this.modalInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceActionBtn() {
    this.actionBtnInstanceList = [];
    this.actionBtn = document.querySelectorAll('.fixed-action-btn');

    if (this.actionBtn) { this.actionBtn.forEach((element, index, array) => {
        this.actionBtnInstanceList.push(M.FloatingActionButton.init(element, {
          direction: 'left',
          hoverEnabled: true
        }));
      }, this);
    };
  }
  destroyInstanceActionBtn() {
    if (this.actionBtnInstanceList.length > 0) {
      this.actionBtnInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  add13Days() {
    let datePikersTo = this.datePikersTo[0];

    if (datePikersTo && datePikersTo.value == '') {
      let dateTemp = new Date(this.datePikersFrom[0].value);
      dateTemp.setDate(dateTemp.getDate() + 13);
      dateTemp = dateTemp.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });

      datePikersTo.value = dateTemp;
      M.updateTextFields();
    }
  }

  initiateInstanceDatePikersTo() {
    this.datePikersToInstanceList = [];
    this.datePikersTo = document.querySelectorAll('.datepicker-date__to');

    if (this.datePikersTo) { this.datePikersTo.forEach((element, index, array) => {
        this.datePikersToInstanceList.push(M.Datepicker.init(element, {
          showClearBtn: true,
          autoClose: true
        }));
      }, this);
    };
  }
  destroyInstanceDatePikersTo() {
    if (this.datePikersToInstanceList.length > 0) {
      this.datePikersToInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceDatePikersFrom() {
    this.datePikersFromInstanceList = [];
    this.datePikersFrom = document.querySelectorAll('.datepicker-date__from');
    let this_this = this;

    if (this.datePikersFrom) { this.datePikersFrom.forEach((element, index, array) => {
        this.datePikersFromInstanceList.push(M.Datepicker.init(element, {
          showClearBtn: true,
          autoClose: true,
          onClose: function () {
            this_this.add13Days();
          }
        }));
      }, this);
    };
  }
  destroyInstanceDatePikersFrom() {
    if (this.datePikersFromInstanceList.length > 0) {
      this.datePikersFromInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceTimePikers() {
    this.timePikersInstanceList = [];
    this.timePikers = document.querySelectorAll('.datepicker-time');
    this.timeIntervalPikers = document.querySelectorAll('.datepicker-time-interval');

    if (this.timePikers) { this.timePikers.forEach((element, index, array) => {
        this.timePikersInstanceList.push(M.Timepicker.init(element, {
          showClearBtn: true,
          autoClose: true
        }));
      }, this);
    };

    if (this.timeIntervalPikers) { this.timeIntervalPikers.forEach((element, index, array) => {
        this.timePikersInstanceList.push(M.Timepicker.init(element, {
          showClearBtn: true,
          autoClose: true,
          twelveHour: false
        }));
      }, this);
    };
  }
  destroyInstanceTimePikers() {
    if (this.timePikersInstanceList.length > 0) {
      this.timePikersInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceCarouselBanner() {
    this.carouselBannerInstanceList = [];
    this.carouselBanner = document.querySelectorAll('.carousel_banner');

    if (this.carouselBanner) { this.carouselBanner.forEach((element, index, array) => {
        this.carouselBannerInstanceList.push(M.Carousel.init(element, {
          fullWidth: true
        }));
      }, this);
    };
  }
  destroyInstanceCarouselBanner() {
    if (this.carouselBannerInstanceList.length > 0) {
      this.carouselBannerInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceCarouselRender() {
    this.carouselRenderInstanceList = [];
    this.carouselRender = document.querySelectorAll('.carousel_render');

    if (this.carouselRender) { this.carouselRender.forEach((element, index, array) => {
        this.carouselRenderInstanceList.push(M.Carousel.init(element, {}));
      }, this);
    };
  }
  destroyInstanceCarouselRender() {
    if (this.carouselRenderInstanceList.length > 0) {
      this.carouselRenderInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceCarouselRender() {
    this.carouselRenderInstanceList = [];
    this.carouselRender = document.querySelectorAll('.carousel_render');

    if (this.carouselRender) { this.carouselRender.forEach((element, index, array) => {
        this.carouselRenderInstanceList.push(M.Carousel.init(element, {}));
      }, this);
    };
  }
  destroyInstanceCarouselRender() {
    if (this.carouselRenderInstanceList.length > 0) {
      this.carouselRenderInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceMaterialbox() {
    this.materialboxInstanceList = [];
    this.materialbox = document.querySelectorAll('.materialboxed');

    if (this.materialbox) { this.materialbox.forEach((element, index, array) => {
        this.materialboxInstanceList.push(M.Materialbox.init(element, {}));
      }, this);
    };
  }
  destroyInstanceMaterialbox() {
    if (this.materialboxInstanceList.length > 0) {
      this.materialboxInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceParallax() {
    this.parallaxInstanceList = [];
    this.parallax = document.querySelectorAll('.parallax');

    if (this.parallax) { this.parallax.forEach((element, index, array) => {
        this.parallaxInstanceList.push(M.Parallax.init(element, {}));
      }, this);
    };
  }
  destroyInstanceParallax() {
    if (this.parallaxInstanceList.length > 0) {
      this.parallaxInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceCollapsible() {
    this.collapsibleInstanceList = [];
    this.collapsible = document.querySelectorAll('.collapsible');

    if (this.collapsible) { this.collapsible.forEach((element, index, array) => {
        this.collapsibleInstanceList.push(M.Collapsible.init(element, {
          inDuration: 450,
          outDuration: 450
        }));
      }, this);
    };
  }
  destroyInstanceCollapsible() {
    if (this.parallaxInstanceList.length > 0) {
      this.parallaxInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceTooltipped() {
    this.tooltippedInstanceList = [];
    this.tooltips        = document.querySelectorAll('.tooltipped_rails');

    if (this.tooltips) { this.tooltips.forEach((element, index, array) => {
        this.tooltippedInstanceList.push(M.Tooltip.init(element, {}));
      }, this);
    };
  }
  destroyInstanceTooltipped() {
    if (this.tooltippedInstanceList.length > 0) {
      this.tooltippedInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceChoice() {
    this.choiceInstanceList = [];
    this.choiceSelect = document.querySelectorAll('.js-choice');

    if (this.choiceSelect) { this.choiceSelect.forEach((element, index, array) => {
        let options = { shouldSort: false };
        if (element.multiple) options.removeItemButton = true;
        this.choiceInstanceList.push(new Choices(element, options));
      }, this);
    };
  }
  destroyInstanceChoice() {
    if (this.choiceInstanceList.length > 0) {
      this.choiceInstanceList.forEach((element) => { element.destroy(); });
    };
  }

  initiateInstanceAutocomplete() {
    this.autocompleteInstanceList = [];
    this.autocomplete = document.querySelectorAll('.autocomplete');

    if (this.autocomplete) { this.autocomplete.forEach((element, index, array) => {
        this.autocompleteInstanceList.push(M.Autocomplete.init(element,
          { data: JSON.parse(element.dataset.listNames) }
        ));
      }, this);
    }
  }
  destroyInstanceAutocomplete() {
    if (this.autocompleteInstanceList.length > 0) {
      this.autocompleteInstanceList.forEach((element) => { element.destroy(); });
    };
  }
};